import '@fontsource/open-sans' // Defaults to weight 400 with normal variant.

import i18n from 'i18next'
import {
    initReactI18next,
    I18nextProvider,
    useTranslation,
} from 'react-i18next'
import React, { useEffect } from 'react'
import enUSTranslation from './src/locales/en_US/translation.json'
import esARTranslation from './src/locales/es_AR/translation.json'
import es419Translation from './src/locales/es_419/translation.json'
import esUSTranslation from './src/locales/es_US/translation.json'
import esESTranslation from './src/locales/es_ES/translation.json'
import { ThemeProvider } from '@mui/material/styles'
import theme from './theme'
import { QueryClientProvider } from '@tanstack/react-query'
import queryClient from './src/services/queryClient'
import { TourProvider } from '@reactour/tour'
import tourStyles from './src/components/Tour'
import '@ecolell/pwa-install'

const resources = {
    'en-US': {
        translation: enUSTranslation,
    },
    'es-AR': {
        translation: esARTranslation,
    },
    'es-ES': {
        translation: esESTranslation,
    },
    'es-419': {
        translation: es419Translation,
    },
    'es-US': {
        translation: esUSTranslation,
    },
    // Add more languages as needed
}

i18n.use(initReactI18next).init({
    resources,
    fallbackLng: 'es-AR', // Default language fallback
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
        escapeValue: false, // React already escapes strings
    },
})

export const wrapPageElement = ({ element }) => {
    const { i18n } = useTranslation()

    useEffect(() => {
        const n = window?.navigator
        const lng = n.language || n.userLanguage
        if (Object.keys(resources).includes(lng)) {
            i18n.changeLanguage(lng)
        }
    }, [])
    return (
        <React.StrictMode>
            <ThemeProvider theme={theme}>
                <TourProvider
                    steps={[]}
                    scrollSmooth={true}
                    showDots={false}
                    showCloseButton={true}
                    showNavigation={true}
                    showPrevNextButtons={true}
                    showBadge={false}
                    styles={tourStyles(theme)}
                >
                    {element}
                </TourProvider>
            </ThemeProvider>
        </React.StrictMode>
    )
}

export const wrapRootElement = ({ element }) => {
    return (
        <QueryClientProvider client={queryClient}>
            <I18nextProvider i18n={i18n}>{element}</I18nextProvider>
        </QueryClientProvider>
    )
}

export const onServiceWorkerUpdateReady = () => {
    const { t } = useTranslation()

    const answer = window.confirm(
        t(
            'This application has been updated. ' +
                'Reload to display the latest version?'
        )
    )

    if (answer === true) {
        window.location.reload()
    }
}
